import { Component, OnInit } from '@angular/core';
import * as Parallax from 'parallax-js';

@Component({
  selector: 'app-map-example',
  templateUrl: './map-example.component.html',
})
export class MapExampleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    if ( window.innerWidth > 500 ) {
      const scene = document.getElementById( 'explore_wrapper' );
      const parallaxInstance = new Parallax( scene, {
        relativeInput: false,
      } );
    }

    
}

}
