<div *ngIf="data" class="snackBar snackBar__{{ data.classStyle }}">
  <div>
    <p [innerHTML]="data.text"></p>
  </div>
  <button *ngIf="data.classStyle === 'cookies'" (click)="close()">
    <i class="material-icons">
      cancel
    </i>
  </button>
</div>
