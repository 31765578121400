import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { AngularMaterialsModule } from '@shared/angular-materials/angular-materials.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '../app-routing.module';
import { environment as env } from '@env/environment';

// i18n
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Components

import { BootstrapModulesModule } from '@shared/bootstrap-modules/bootstrap-modules.module';
import { SnackBarTemplateComponent } from '@shared/snack-bar-template/snack-bar-template.component';
import { PasswordRecoveryViaEmailComponent } from './password-recovery-via-email/password-recovery-via-email.component';
import { CompleteProfileFormComponent } from './complete-profile-form/complete-profile-form.component';
import { DeleteUserFormComponent } from './delete-user-form/delete-user-form.component';
import { DeleteUserRequestComponent } from './delete-user-request/delete-user-request.component';
import { FacebookLoginButtonComponent } from './facebook-login-button/facebook-login-button.component';
import { FAQsRowComponent } from './faqs-row/faqs-row.component';
import { PasswordUpdateFormComponent } from './password-update-form/password-update-form.component';
import { BasicAccordionComponent } from './basic-accordion/basic-accordion.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { ModalTemplateComponent } from './modal-template/modal-template.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ContactsFormComponent } from './contacts-form/contacts-form.component';
import { NumberFormatPipe } from '@helpers';
import { UpdateProfileFormComponent } from './update-profile-form/update-profile-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';


@NgModule({
  declarations: [
    SnackBarTemplateComponent,
    ModalTemplateComponent,
    PasswordRecoveryViaEmailComponent,
    CompleteProfileFormComponent,
    DeleteUserFormComponent,
    DeleteUserRequestComponent,
    FacebookLoginButtonComponent,
    FAQsRowComponent,
    PasswordUpdateFormComponent,
    BasicAccordionComponent,
    PdfViewerComponent,
    ProfileFormComponent,
    RegisterFormComponent,
    LoginFormComponent,
    ContactsFormComponent,
    UpdateProfileFormComponent,
    ContactFormComponent,


  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    AngularMaterialsModule,
    AngularFontAwesomeModule,
    BootstrapModulesModule,


    // i18n
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
  ],
  entryComponents: [
    SnackBarTemplateComponent,
    ModalTemplateComponent
  ],
  exports: [
    TranslateModule,
    // Components

    PasswordRecoveryViaEmailComponent,
    CompleteProfileFormComponent,
    DeleteUserFormComponent,
    DeleteUserRequestComponent,
    FacebookLoginButtonComponent,
    FAQsRowComponent,
    PasswordUpdateFormComponent,
    BasicAccordionComponent,
    PdfViewerComponent,
    ProfileFormComponent,
    RegisterFormComponent,
    LoginFormComponent,
    ContactsFormComponent,
    UpdateProfileFormComponent,
    ContactFormComponent,


  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
  ]
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${env.i18nPrefix}/assets/i18n/`,
    '.json'
  );
}
