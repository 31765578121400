import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-why-choose',
  templateUrl: './why-choose.component.html',
})
export class WhyChooseComponent implements OnInit {

  tabs: any = [];

  constructor(
    private translate: TranslateService,
  ) {
    this.translate.get( 'app.components.why.tabs' ).subscribe( x => this.tabs = x );
  }

  ngOnInit() {

  }

}
