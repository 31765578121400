import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-other-cities',
  templateUrl: './other-cities.component.html',
})
export class OtherCitiesComponent implements OnInit {

  other_cities: any[] = [];

  constructor(
    private translate: TranslateService,
  ) {
    this.translate.get( 'app.components.other_cities.items' ).subscribe( x => this.other_cities = x );
  }

  ngOnInit() {
  }

}
