import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { AngularMaterialsModule } from '@shared/angular-materials/angular-materials.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '../app-routing.module';
import { environment as env } from '@env/environment';

// i18n
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { StringLengthPipe, NumberFormatPipe } from '@helpers';

// Components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { IntroductionComponent } from './introduction/introduction.component';
import { WhyChooseComponent } from './why-choose/why-choose.component';
import { WhatIsComponent } from './what-is/what-is.component';
import { ExploreComponent } from './explore/explore.component';
import { MapExampleComponent } from './map-example/map-example.component';
import { OtherCitiesComponent } from './other-cities/other-cities.component';


@NgModule({
  declarations: [
    StringLengthPipe,
    NumberFormatPipe,

    HeaderComponent,
    FooterComponent,
    IntroductionComponent,
    WhyChooseComponent,
    WhatIsComponent,
    ExploreComponent,
    MapExampleComponent,
    OtherCitiesComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    AngularMaterialsModule,
    AngularFontAwesomeModule,


    // i18n
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
  ],
  exports: [
    TranslateModule,
    StringLengthPipe,
    NumberFormatPipe,
    HeaderComponent,
    FooterComponent,
    IntroductionComponent,
    WhyChooseComponent,
    WhatIsComponent,
    ExploreComponent,
    MapExampleComponent,
    OtherCitiesComponent,


  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
    StringLengthPipe,
    NumberFormatPipe,

  ]
})
export class ComponentsModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${env.i18nPrefix}/assets/i18n/`,
    '.json'
  );
}
