import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';


(window as any).global = window;
(window as any).process = {
  env: { DEBUG: undefined },
};



if ( environment.variables.isTesting || environment.variables.isProduction ) {
  enableProdMode();
}

if ( environment.variables.isProduction ) {
  window.console.log = function(){};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

