import { Injectable } from '@angular/core';

declare let gtag: any; // Declare ga as a function

@Injectable()
export class GoogleAnalyticsService {

  constructor() { }

  public  genericEmitter( item1, item2 ) {
      gtag( item1, item2 );
  }

  public pageEmitter( url: string ) {
    gtag( 'set', 'page', url );
  }

  public eventEmitter( eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null ) {
    gtag( 'send', 'event', eventCategory, eventAction, eventLabel, eventValue, {} );
  }

}
