import { Pipe, PipeTransform } from '@angular/core';

/**
 * Utility pipe, returns a cutted string if the provided lenght is minor than the string's length
 */

@Pipe({ name: 'stringLength', pure: false })
export class StringLengthPipe implements PipeTransform {
  constructor() {
  }


  transform( value: any, maxLength: number = 15 ): string {

    if ( value ) {
      if ( value.length > maxLength ) {
        return `${ value.slice( 0, ( maxLength - 1 ) ) }...`;
      }

      return value;

    } else {
      return '';
    }

  }

}
