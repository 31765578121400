import { Injectable } from '@angular/core';
import { environment as env } from '@env/environment';

/**
 * Helper class, used to manage the local storage content of the browser
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageHelperService {

  private storageName = env.storageName;

  constructor() { }

  // sets a new key in the locastorage or updates an existing one
  public setData( keyName: string, content: any ): any {

    let appData: any = JSON.parse( localStorage.getItem( this.storageName ) );

    if ( appData === null ) { appData = {}; }
    if ( appData[ keyName ] ) {

      appData[ keyName ] = ( typeof appData[ keyName ] === 'string' || Array.isArray( appData[ keyName ] ) )
                            ? content
                            : { ...appData[ keyName ], ...content }

    } else {
      appData[ keyName ] = content;
    }


    localStorage.setItem( this.storageName, JSON.stringify( appData ) );

    this.emitStorage();

    return appData;

  }

  // returns the complete storage data or a simply key inside it
  // if the localstorage or the inner key does not exists returns false
  public getData( keyName ?: string ): any {

    const storageData = JSON.parse( localStorage.getItem( this.storageName ) );

    if ( keyName === 'codeWon' && storageData === null ) { return null; }

    if ( storageData === null ) return false;


    if ( keyName ) {
      if ( storageData.hasOwnProperty( keyName ) ) {
        return storageData[ keyName ];
      } else {
        return false;
      }
    }

    return storageData;
  }

  public deleteStorage(): void {
    localStorage.removeItem( this.storageName );
    this.emitStorage();
    this.deleteStorageEvent();
  }

  private deleteStorageEvent(): void {
    window.removeEventListener( 'onCustomStorage', () => console.log( 'storage event removed' ) );
  }

  private emitStorage(): void {
    window.dispatchEvent( new Event( 'onCustomStorage' ) );
  }

}
