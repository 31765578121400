<div class="container">

  <div class="divider divider__30"></div>

  <div class="row field_row field_row__psw-email-recovery">

    <form [formGroup]="emailForm" class="container">

      <div class="row">
          <mat-form-field class="col-10 offset-1">
            <input
              matInput
              type="email"
              name="email"
              [placeholder]="'app.common.forms.email' | translate"
              formControlName="email"
              required
            />
            <mat-error *ngFor="let err of keys(f['email'].errors)">
              {{ 'app.errors.email.' + err | translate }}
            </mat-error>
          </mat-form-field>
      </div>

      <div class="divider divider__50"></div>

      <div class="container text-center alt-default-theme">

        <button
          type="button"
          mat-raised-button
          color="accent"
          (click)="onSubmitEmail()"
        >
        {{ 'app.common.buttons.submit' | translate }}
        </button>

      </div>

    </form>

    <div class="divider divider__50"></div>

  </div>

</div>
