import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiManagerService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { ModalTemplateComponent } from '../../shared/modal-template/modal-template.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
})
export class ContactFormComponent implements OnInit {

  contactForm: FormGroup;
  dialogRef: any;
  resultMessage: any;

  constructor(
    private fb : FormBuilder,
    private apiService: ApiManagerService,
    private dialog    : MatDialog,
    private translate : TranslateService,
  ) { }

  

  private buildForm() {
    this.contactForm = this.fb.group({
      name       : [ '', [ Validators.required, ] ],
      surname    : [ '', [ Validators.required, ] ],
      city       : [ '', [ Validators.required, ] ],
      email      : [ '', [ Validators.required, Validators.email ] ],
      message    : [ '', [ Validators.required, Validators.maxLength( 1000 ) ] ],
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  keys(obj: any): any[] {
    if (!obj) {
      return [];
    }
    return Object.keys(obj);
  }

  public async onSubmit() : Promise<void> {
    
    if ( !this.contactForm.valid ) { return; }

    const apiResponse = await this
      .apiService
      .sendContactData( this.contactForm.value )
      .toPromise()
      .then( x => x );
      
      if ( apiResponse.statusText === 'SUCCESS' ) {
        this.resultMessage = await this.translate.get( 'app.modals.contact_form.success' ).toPromise().then( x => x );
      } else {
        this.resultMessage = await this.translate.get( 'app.modals.contact_form.error' ).toPromise().then( x => x );
      }

      this.dialogRef = this.dialog.open(ModalTemplateComponent, {
        disableClose: false,
        id: 'contact_form-modal',
        data: {
          ...this.resultMessage,
          isForm: false,
        }
      });

  }

  ngOnInit() {
    this.buildForm();
  }

}
