import { FormControl, ValidatorFn, AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

export class CustomValidators {

  /**
   * Checks if validation on multpiple checkboxes
   *
   * @returns {Object|null} the result of the validation
   */
    static multipleCheckboxes(): ValidatorFn {

        return ( control: FormGroup ): { [key: string]: any } | null => {

          let status: { [key: string]: any } | any = false;
          const taskValues = control.value;

          Object.keys( taskValues ).forEach( item => {
            if ( taskValues[ item ] !== false ) {

              status = true;

              const htmlElement = document.getElementsByClassName( item )[ 0 ];

              if ( !htmlElement ) { return null; }

              const classContent = htmlElement.classList;

              if ( classContent.contains( 'with_text' ) ) {

                const currIndex = item.split( '_' )[ 1 ];
                const siblingLabelValue = document.getElementsByClassName( `text_label_${ currIndex }` )[ 0 ][ 'value' ];

                if ( siblingLabelValue === '' || siblingLabelValue === undefined ) {
                  status = { missingCheckboxLabel: 'missingCheckboxLabel' };
                }

              }

            }
          } )

          if ( status === true ) {
            return null;
          } else if (  status === false ) {
            return { checkbox: 'leastOne' };
          } else {
            return status;
          }

       }
    }

    /**
   * Checks if the validated field contains the right extension of a file
   *
   * @returns {Object|null} the result of the validation
   */
    static extensionValidator( allowedTypes: string[] ): ValidatorFn {
      return ( control: FormControl ): { [ key: string ]: any } | null => {
        const fileName = control.value;
        if ( fileName === '' ) { return null; }
        const ext      = fileName.split( '.' ).reverse()[ 0 ].toLowerCase();
        if ( !allowedTypes.some( x => x === ext ) ) {
          return { notAllowedExtension: true };
        }
        return null;
      }
    }

    static sameValueMatch( otherField: string, directValue: any = false ): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            if (!control.parent) {
                return null;
            }

            let otherValue: any = '';

            if ( directValue ) {
              otherValue = directValue;
            } else {
              otherValue = control.parent.value[otherField];
            }

            // Reset same_value_match error on other control
            const otherControl: FormControl = control.parent.controls[otherField];
            if ( !directValue && otherValue === control.value && otherControl.hasError('same_value_match')) {
                let errors = { ...otherControl.errors };
                delete errors.same_value_match;
                if (Object.keys(errors).length === 0) {
                    errors = null;
                }
                otherControl.setErrors(errors);
            }
            return (otherValue === control.value)
                    ? null
                    : { same_value_match: control.value };
        };
    }

    /**
     * Checks if the other field given, contains a different value
     *
     * @param {String} otherField  the other field to compare during the validation
     *
     * @returns {Object|null} validation result
     */
    static differentValueMatch(otherField: string): ValidatorFn {
      return (control: FormControl): { [key: string]: any } | null => {
          if (!control.parent) {
              return null;
          }
          const otherValue = control.parent.value[otherField];

          // Reset same_value_match error on other control
          const otherControl: FormControl = control.parent.controls[otherField];
          if (otherValue !== control.value && otherControl.hasError('different_value_match')) {
              let errors = { ...otherControl.errors };
              delete errors.same_value_match;
              if (Object.keys(errors).length !== 0) {
                  errors = null;
              }
              otherControl.setErrors(errors);
          }
          return (otherValue !== control.value)
                  ? null
                  : { different_value_match: control.value };
      };
  }
}
