<div class="col-11 offset-0_5-md col-lg-12 offset-lg-0">

	<div class="row">
		<div class="col-12">
			<h2 class="text-center" [innerHTML]="'app.components.other_cities.title' | translate"></h2>
			<p class="text-center w-100 w-md-75 w-lg-50" [innerHTML]="'app.components.other_cities.text' | translate"></p>
		</div>
	</div>

	<div class="divider divider__50"></div>

	<div *ngIf="other_cities.length > 0" class="row d-flex cities-grid__wrapper">

		<div *ngFor="let city of other_cities; let cityIDX = index; let lastIDX = last;"
			class="col-12 col-sm-6 col-md-4 col-lg-3 p-2 mb-2 cities-grid__item" data-aos="fade-up" data-aos-offset="100"
			data-aos-duration="800" attr.data-aos-delay="{{ 100 + ( cityIDX * 100 ) }}">

			<mat-card
				class="d-flex align-items-center justify-content-start flex-row px-4"
				[ngClass]="{ 'background__paleBrightPurple': lastIDX }"
			>
				<div>
					<img src="/assets/images/other_cities/{{ city.image }}" alt="{{city.text}}">
				</div>
				<mat-card-content class="pl-3">
					<p class="text__rollingStoneGrey m-0" [innerHTML]="city.text"></p>
				</mat-card-content>
			</mat-card>

		</div>

	</div>

</div>