import { Injectable } from '@angular/core';


/**
 * Helper pipe, contains utilieties for DOM manipulation
 */

@Injectable()
export class DomTools {

  constructor() {}


  /**
   * returns the list of the parents of a given element ( like jquery's .parent() )
   *
   * @param {HTMLelement} element  the dom element
   * @param {string}      selector the class or id selector
   *
   * @return {HTMLelement}
   */
  public getParents ( element: any, selector: string ) {
    const elements = [];
    let elem = element;
    const ishaveselector = selector !== undefined;

    while ((elem = elem.parentNode) !== null) {

      if (elem.nodeType !== Node.ELEMENT_NODE ) {
        continue;
      }

      if (!ishaveselector || ( elem.classList.contains( selector ) || elem.id.indexOf( selector ) > -1 ) ) {
        elements.push(elem);
      }
    }

    return elements;
  }

  /**
   * Checks if the parent of the given element has a given selector
   *
   * @param {HTMLelement} child    starting element
   * @param {string}      selector the id or class selector
   *
   * @returns {boolean}
   *
   */

  public parentHasAttribute( child: any, selector: string ) {
    return this.getParents( child, selector ).some( parent => parent.classList.contains( selector ) || parent.id.indexOf( selector ) > -1 );
  }

  /**
   * returns an array of the given length, in order to simulate an API response content
   *
   * @param {number} qty the length of the desired list
   *
   * @returns {Array}
   *
   */
  public numberArray( qty: number ) {
    const newArray = Array.from( Array( qty ), ( _, x ) => x );
    return newArray;
  }

}
