import { Injectable } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageHelperService } from '../_helpers/local-storage-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarTemplateComponent } from '@shared/snack-bar-template/snack-bar-template.component';
import { Observable } from 'rxjs';



import { environment as env } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    constructor(
        private router       : Router,
        private storage      : LocalStorageHelperService,
        private translate    : TranslateService,
        private snack        : MatSnackBar,
    ) {

    }

    private getModal( style: string, text: string ) {
      return new Promise( ( resolve, reject ) => {
        this.translate.get( `app.snackBar.${ text }` )
          .subscribe( trans => {

              this.snack.openFromComponent( SnackBarTemplateComponent, {
                data: {
                  text: trans,
                  classStyle: style,
                },
                duration: 3000,
                verticalPosition: 'top',
              } )
              .afterDismissed()
              .subscribe( x => resolve( x ) );
            });
      } );
    }

    public get currentUserValue(): any {
        const userValues = this.storage.getData( 'user_data' );
        return userValues;
    }

    public getCurrentUser(): any {
        return this.storage.getData( 'user_data' );
    }

    /**
     * double call to api, in order to perform a srp login
     * @param email 
     * @param password
     */
    public async login ( email: string, password: string ) {


    }

    /**
     * remove user from local storage to log user out
     */
    logout() {
        this.storage.deleteStorage();
        this.translate.get( 'app.navigation.noLog.login' ).subscribe( x => this.router.navigate( [ `${ x }` ] ) );
    }

    /**
     * Checks if user is already authenticated with a valid token
    */
    public isAuthenticated( redirect: boolean = true ): boolean {

        const userExists   = this.storage.getData( 'user_data' );

        const userHasToken = userExists !== false ? userExists[ 'AuthenticationResult' ][ 'AccessToken' ] : false;

        if ( redirect === true ) {

        }

        console.log( 'is authenticated', ( ( userExists && userHasToken ) ? true : false ) )

        return ( userExists && userHasToken ) ? true : false;

    }

    public reloadUser(): any {
      return new Observable( (observer ) => {
      });
    }

}
