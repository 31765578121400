import { Injectable } from '@angular/core';

import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PaginatorTranslatorService {

  constructor(private readonly translate: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    this.translate.get( 'app.tables.pagination.ITEMS_PER_PAGE_LABEL' ).subscribe( x => paginatorIntl.itemsPerPageLabel = x );
    this.translate.get( 'app.tables.pagination.NEXT_PAGE_LABEL' ).subscribe( x => paginatorIntl.nextPageLabel = x );
    this.translate.get( 'app.tables.pagination.PREVIOUS_PAGE_LABEL' ).subscribe( x => paginatorIntl.previousPageLabel = x );
    this.translate.get( 'app.tables.pagination.FIRST_PAGE_LABEL' ).subscribe( x => paginatorIntl.firstPageLabel = x );
    this.translate.get( 'app.tables.pagination.LAST_PAGE_LABEL' ).subscribe( x => paginatorIntl.lastPageLabel = x );
    paginatorIntl.getRangeLabel     = this.getRangeLabel.bind(this);
    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
        return this.translate.instant('app.tables.pagination.RANGE_PAGE_LABEL_1', { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.instant( 'app.tables.pagination.RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
  }

}
