<nav class="navbar sticky-top navbar-light background__white">
  <div class="w-100 d-flex align-items-center justify-content-between flex-row">
    <div>
      <a href="#page_top">
        <img src="/assets/images/icons/{{ 'app.header.logo.image' | translate }}" alt="{{ 'app.header.logo.alt' | translate }}">
      </a>
    </div>
  
    <div>
      <a mat-raised-button color="primary" href="#scroll_target"> {{ 'app.common.buttons.require_demo' | translate }} </a>
    </div>
  </div>
</nav>