<div class="col-11 offset-0_5-md col-lg-12 offset-lg-0 ">

    <div class="row d-flex align-items-center justify-content-center flex-column flex-md-row">

        <div class="col-12 col-md-6">
            <h1 class="text-center text-md-left mb-4 mb-md-0" [innerHTML]="'app.components.introduction.title' | translate"></h1>
            <div class="d-none d-md-block mt-4">
                <h3 [innerHTML]="'app.components.introduction.text' | translate"></h3>
                <a mat-raised-button color="primary" class="mt-4" href="#scroll_target"> {{ 'app.common.buttons.require_demo' | translate }} </a>
            </div>
            
        </div>
        <div class="col-12 col-md-6 mb-5 mb-md-0">
            <div class="d-flex align-items-center justify-content-center skew-images">
                <img class="skew-images__front background__drop" src="/assets/images/introduction/{{ 'app.components.introduction.image.frame' | translate }}" alt="{{ 'app.components.introduction.image.alt' | translate }}">
                <img class=" skew-images__back" src="/assets/images/introduction/{{ 'app.components.introduction.image.background' | translate }}" alt="{{ 'app.components.introduction.image.alt' | translate }}">
            </div>
            <div class="d-md-none text-center">
                <h3 class="mt-4" [innerHTML]="'app.components.introduction.text' | translate"></h3>
                <a mat-raised-button color="primary" class="mt-4" href="#scroll_target"> {{ 'app.common.buttons.require_demo' | translate }} </a>
            </div>
        </div>

    </div>

</div>
