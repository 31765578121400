<div class="col-11 offset-0_5-md col-lg-12 offset-lg-0">

    <div class="row">
        <div class="col-12">
            <h2 class="text-center" [innerHTML]="'app.components.what.title' | translate"></h2>
        </div>
    </div>

    <div class="divider divider__50"></div>

    <div *ngIf="items.length > 0" class="row d-flex images-grid__wrapper">

        <div *ngFor="let item of items; let itemIDX = index;"
             class="col-12 col-sm-6 col-md-4 mb-5 images-grid__item"
             data-aos="fade-up"
             data-aos-offset="100"
             data-aos-duration="800"
             attr.data-aos-delay="{{ 100 + ( itemIDX * 100 ) }}"
        >

            <div class="row p-2">

                <div class="w-100 text-center mb-4 images-grid__item__image">
                    <img src="/assets/images/what/{{ item.image }}" alt="{{item.title}}">
                </div>

                <div class="w-100 d-flex align-items-center justify-content-center flex-column images-grid__item__text">
                    <h3 class="w-100 text-center mb-3" [innerHTML]="item.subtitle"></h3>
                    <p class="w-100 text-center" [innerHTML]="item.text"></p>
                </div>

            </div>

        </div>

    </div>

</div>
