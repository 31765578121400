import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@services';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  userMenu: any[] = [];

  constructor(
    private translate : TranslateService,
    private auth      : AuthenticationService
  ) {
    this.translate.get( 'app.navigation.log.profile.list' ).subscribe( x => this.userMenu = Object.keys( x ).map( y => x[ y ] ) );
  }

  private logOut(): void {
    this.auth.logout();
  }

  public runAction( action: string ): void {
    switch ( action ) {
      case 'logout':
        this.logOut();
      break;
      default:
        console.error( 'Undefined action!' )
      break;
    }
  }


  ngOnInit() {

  }

}
