import { NgModule, Injectable, Component } from '@angular/core';

import { HomePageComponent } from '@pages/home-page/home-page.component';


export const It_Routes = [
  // it

  //   PROFILO
  {
    path: '',
    component: HomePageComponent,
    data: {
      pageTitle: 'Accesso',
    },
  },
];
