import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '@env/environment';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarTemplateComponent } from '@shared/snack-bar-template/snack-bar-template.component';

@Injectable()
export class HttpResponseInterceptorService implements HttpInterceptor {

  constructor(
    private snack     : MatSnackBar,
    private translate : TranslateService,
    private route     : Router,
  ){}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if ( request.url.includes( env.api.endpoint ) ) {

      return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {

          if ( event instanceof HttpResponse ) {
            if ( event ) {

              if ( event.body.hasOwnProperty( 'error' ) ) {



                throw new HttpErrorResponse({
                  error: event.body,
                  headers: event.headers,
                  status: event.status,
                  statusText: event.statusText,
                  url: event.url
                });
              }

              event = event.clone({ body: this.modifyBody(event.body) });
            } else {
              throw new HttpErrorResponse({
                error: event.body,
                headers: event.headers,
                status: event.status,
                statusText: event.statusText,
                url: event.url
              });
            }
            return event;
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private modifyBody( body: any ) {
    return body.data;
  }
}
