import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarTemplateComponent } from '@shared/snack-bar-template/snack-bar-template.component';

import { LocalStorageHelperService } from './local-storage-helper.service';
import { Router } from '@angular/router';

import { environment as env } from '@env/environment';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    apiAvailableErrors: any = {};

    constructor(
        private storage   : LocalStorageHelperService,
        private route     : Router,
        private snack     : MatSnackBar,
        private translate : TranslateService,
        ) {
            this.translate.get( 'app.errors.api' ).subscribe( x => this.apiAvailableErrors = x );
        }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if ( !request.url.includes( '' ) ) {

            return next.handle(request);

        } else {
            return next.handle(request);
        }



    }
}
