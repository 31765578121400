import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@services';
import { TranslateService } from '@ngx-translate/core';

import { environment as env } from '@env/environment';
import { GoogleAnalyticsService } from './core/_services/google-analytics.service';
import { LocalStorageHelperService } from './core/_helpers/local-storage-helper.service';
import { Title } from '@angular/platform-browser';

import { MatSnackBar } from '@angular/material';
import { SnackBarTemplateComponent } from '@shared/snack-bar-template/snack-bar-template.component';

import AOS from 'aos';

import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

@Component({ selector: 'app-root', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit {
    noLogMenu       : any;
    loggedMenu      : any;
    isUserLogged    = false;
    isLocal         = env.variables.isLocal;
    showDisclaimer  = false;

    private _mobileQueryListener: () => void;

    constructor(
        private translate             : TranslateService,
        private authenticationService : AuthenticationService,
        private router                : Router,
        private pageTitle             : Title,
        private activeRoutes          : ActivatedRoute,
    ) {

        // loader component
        this.isUserLogged = this.authenticationService.isAuthenticated();
        translate.setDefaultLang('it');
        translate.use('it');
    }

    ngOnInit() {

      AOS.init();

        // TITLE OPs
        this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.activeRoutes)
      .map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      })
      .filter((route) => route.outlet === 'primary')
      .mergeMap((route) => route.data)
      .subscribe((event) => this.pageTitle.setTitle(event['pageTitle']));

      }


}
