import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import axios from 'axios'
import { SRPClient, calculateSignature, getNowString } from 'amazon-user-pool-srp-client';

import { environment as env } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor(

  ) { }

  private call (action, body, url: any = false) {

    const headers = !url
    ? {
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': action
    }
    : {
      'Content-Type': 'application/json',
    };

    const request = {
      url: !url ? env.aws.cognito.PoolLocation : url,
      method: 'POST',
      headers: headers,
      data: JSON.stringify(body),
      transformResponse: (data) => data
    } as any;

    return axios( request )
    .then((result) => { console.log( `axios => ${ result }` ); return JSON.parse(result.data) })
    .catch((error) => {
      const _err = JSON.parse(error.response.data)
      const err = new Error()
      err[ 'code' ] = _err.__type
      err[ 'message' ] = _err.message
      return Promise.reject(err)
    })
  }

  public login ( email: string, password: string ) {
    const userPoolId = env.aws.cognito.UserPoolId.split('_')[1];
    const srp = new SRPClient(userPoolId)
    const SRP_A = srp.calculateA();

    return this.call( '', { email: email, srp_a: SRP_A }, 'https://9c3ciztask.execute-api.eu-west-1.amazonaws.com/dev/init-auth' )
      .then( x => {
        const y = x.body.data;
        const hkdf = srp.getPasswordAuthenticationKey(y.ChallengeParameters.USER_ID_FOR_SRP, password, y.ChallengeParameters.SRP_B, y.ChallengeParameters.SALT)
        const dateNow = getNowString()
        const signatureString = calculateSignature(hkdf, userPoolId, y.ChallengeParameters.USER_ID_FOR_SRP, y.ChallengeParameters.SECRET_BLOCK, dateNow)
        return this.call( '', {
          challenge_name : y.ChallengeName,
          session : y.Session ? y.Session : false,
          ChallengeResponses: {
              password_claim_signature: signatureString,
              password_claim_secret_block: y.ChallengeParameters.SECRET_BLOCK,
              timestamp : dateNow,
              username  : y.ChallengeParameters.USER_ID_FOR_SRP,
            }
        }, 'https://9c3ciztask.execute-api.eu-west-1.amazonaws.com/dev/auth-rsp' );

      }).then( b => b.body.data )



  }

  /* Additional calls as part of standalone user pool client */

  public refreshCredentials (refreshToken) {
    return this.call('AWSCognitoIdentityProviderService.InitiateAuth', {
      ClientId: env.aws.cognito.ClientId,
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken
      }
    })
    .then(({ AuthenticationResult }) => ({ ...AuthenticationResult, RefreshToken: AuthenticationResult.RefreshToken || refreshToken }))
  }

  public signup (Username, Password, AttributeList) {
    return this.call('AWSCognitoIdentityProviderService.SignUp', {
      ClientId: env.aws.cognito.ClientId,
      Username,
      Password,
      UserAttributes: Object.keys(AttributeList).map((key) => ({ Name: key, Value: AttributeList[key] }))
    })
  }

  public resendConfirmationCode (Username) {
    return this.call('AWSCognitoIdentityProviderService.ResendConfirmationCode', {
      ClientId: env.aws.cognito.ClientId,
      Username
    })
  }

  public signupConfirm (Username, ConfirmationCode, ForceAliasCreation) {
    return this.call('AWSCognitoIdentityProviderService.ConfirmSignUp', {
      ClientId: env.aws.cognito.ClientId,
      Username,
      ConfirmationCode,
      ForceAliasCreation
    })
  }

}
