<div class="container-fluid">

          <app-introduction data-aos="fade-up" data-aos-offset="100" data-aos-delay="500" data-aos-duration="1000" class="row section"></app-introduction>
          <app-why-choose data-aos="fade-up" data-aos-offset="100" data-aos-delay="600" data-aos-duration="400" class="row section"></app-why-choose>
          <app-what-is data-aos="fade-up" data-aos-offset="100" class="row section"></app-what-is>
          <app-explore data-aos="fade-up" data-aos-offset="100" class="row section"></app-explore>
          <app-map-example data-aos="fade-up" data-aos-offset="100" class="row section"></app-map-example>
          <app-other-cities data-aos="fade-up" data-aos-offset="100" class="row section"></app-other-cities>
          <div id="scroll_target" class="row"></div>
          <app-contact-form data-aos="fade-up" data-aos-offset="100" class="row section"></app-contact-form>


</div>


<!-- 

data-aos="fade-up"
data-aos-offset="200"
data-aos-delay="50"
data-aos-duration="1000"
data-aos-easing="ease-in-out"
data-aos-mirror="true"
data-aos-once="false"
data-aos-anchor-placement="top-center"
    
 -->