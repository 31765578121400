import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';




import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
} from 'ngx-ui-loader';

// i18n
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material';
import localeIt from '@angular/common/locales/it';
import { PaginatorTranslatorService } from '@shared/paginator-translator.service';
import { registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';



// Resuming modules
import { AngularMaterialsModule } from '@shared/angular-materials/angular-materials.module';
import { ComponentsModule } from '@components/components.module';
import { SharedModule } from '@shared/shared.module';
import { PagesModule } from '@pages/pages.module';
import { FlexLayoutModule } from '@angular/flex-layout';

// Helpers
import {
  ErrorInterceptor,
  HttpHeadersInterceptorService,
  HttpResponseInterceptorService,
} from '@helpers';

// Analytics
import { GoogleAnalyticsService } from '@services';
import { DeviceDetectorService } from 'ngx-device-detector';

import DateRangeService from '@utils/date-range.service';


import { environment as env } from '@env/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    'blur': 1,
    'fgsColor': '#ee6723',
    'fgsPosition': 'center-center',
    'fgsSize': 120,
    'fgsType': 'ball-spin-fade-rotating',
    // 'fgsType': 'three-strings',
    'gap': 60,
    'logoPosition': 'center-center',
    'logoSize': 150,
    'logoUrl': '../assets/images/advice.logo.png',
    'overlayColor': 'rgba(40, 40, 40, 0.4)',
    'pbColor': '#ee6723',
    'pbDirection': 'ltr',
    'pbThickness': 7,
    'hasProgressBar': true,
    'textColor': '#FFFFFF',
    'textPosition': 'center-center',
    // 'threshold': 1000,
};

registerLocaleData( localeIt, 'en' );

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,

    //i18n
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),

    // Resuming Modules
    AngularMaterialsModule,
    FlexLayoutModule,
    ComponentsModule,
    PagesModule,
    SharedModule,
    BrowserAnimationsModule,
  ],

  entryComponents: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    
    { provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorTranslatorService(translateService).getPaginatorIntl()
    },
    GoogleAnalyticsService,
    // DateRangeService,
    DeviceDetectorService,


],
  bootstrap: [AppComponent],
  exports: [

  ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${env.i18nPrefix}/assets/i18n/`,
    '.json'
  );
}
