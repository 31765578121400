<div class="col-11 offset-0_5-md col-lg-12 offset-lg-0">

	<div class="row">
		<div class="col-12">
			<h2 class="text-center" [innerHTML]="'app.components.why.title' | translate"></h2>
		</div>
	</div>

	<div class="divider divider__50"></div>

	<div class="row d-flex align-items-center justify-content-center flex-column flex-lg-row">

		<div class="col-12 col-lg-6 lg-5 mb-5 mb-lg-0 text-center text-lg-left" data-aos="fade-right" data-aos-offset="300" data-aos-duration="500">
			<img style="max-width: 90%;" src="/assets/images/why/{{ 'app.components.why.image.src' | translate }}" alt="{{ 'app.components.why.image.alt' | translate }}">
		</div>
		<div class="col-12 col-lg-6">

			<div class="row why-cards__wrapper" *ngIf="tabs.length > 0">

				<div class="col-12 p-2"
					*ngFor="let tab of tabs; let tabIDX = index; let lastI = last;"
					[ngClass]="{ 'mb-4': !lastI }" 
				>
					<mat-card  class="w-100 d-flex align-items-start justify-content-start flex-row why-cards__item">
						<div>
							<button mat-fab color="accent" class="em-1_7 font__inter__bold">
								{{ tabIDX + 1 }}
							</button>
						</div>
						<mat-card-content>
							<div class="pl-4 d-flex align-items-start justify-content-between flex-column">
								<h4 class="m-0" [innerHTML]="tab.title"></h4>
								<p class="m-0 text__rollingStoneGrey" [innerHTML]="tab.text"></p>
							</div>
						</mat-card-content>
					</mat-card>
				</div>

				

			</div>

		</div>

	</div>

</div>