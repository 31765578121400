import { NgModule, Injectable, Component } from '@angular/core';

import { HomePageComponent } from '@pages/home-page/home-page.component';



export const En_Routes = [
  // en

  //   PROFILO
  {
    path: '',
    component: HomePageComponent,
    data: {
      pageTitle: 'Access',
    },
  },
];
