<div class="col-11 offset-0_5-md col-lg-12 offset-lg-0">

    <div class="row d-flex align-items-center justify-content-center flex-column flex-md-row mb-5 mb-lg-0">

        <div class="col-12 col-md-6 d-flex align-items-center justify-content-center flex-column order-2 order-md-1 mt-5 mt-md-0">
            <div id="explore_wrapper" class="d-flex align-items-center justify-content-center flex-column explore__wrapper">
                <img data-depth="1" class="explore__item explore__item-background" src="/assets/images/explore/{{ 'app.components.explore.image.background' | translate }}" alt="{{ 'app.components.explore.image.alt' | translate }}" />
                <img data-depth="0.8" class="background__drop explore__item explore__item-graph_1" src="/assets/images/explore/{{ 'app.components.explore.image.graph_1' | translate }}" alt="{{ 'app.components.explore.image.alt' | translate }}" />
                <img data-depth="0.6" class="background__drop explore__item explore__item-graph_2" src="/assets/images/explore/{{ 'app.components.explore.image.graph_2' | translate }}" alt="{{ 'app.components.explore.image.alt' | translate }}" />
                <img data-depth="0.4" class="background__drop explore__item explore__item-frame_1" src="/assets/images/explore/{{ 'app.components.explore.image.frame_1' | translate }}" alt="{{ 'app.components.explore.image.alt' | translate }}" />
                <img data-depth="0.2" class="background__drop explore__item explore__item-frame_2" src="/assets/images/explore/{{ 'app.components.explore.image.frame_2' | translate }}" alt="{{ 'app.components.explore.image.alt' | translate }}" />
            </div>
        </div>
        <div class="col-12 col-md-5 offset-md-1 order-1 order-md-2 mb-5 mb-md-0 text-center text-md-left">
            <h2 [innerHTML]="'app.components.explore.title' | translate"></h2>
            <p [innerHTML]="'app.components.explore.text' | translate"></p>
            <a class="m-auto float-md-left d-flex align-items-center justify-content-center flex-row underlined__false with_icon"
                href="{{ 'app.components.explore.CTA_url' | translate }}"
                target="_blank"
            >
                {{ 'app.common.buttons.discover_more' | translate }}
                <span class="material-icons text__color "> chevron_right </span>
            </a>
        </div>

    </div>

</div>
