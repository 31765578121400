import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

import { environment as env } from '@env/environment';
import { Observable } from 'rxjs';

(window as any).global = window;

@Injectable({
  providedIn: 'root'
})
export class AwsManagerService {

  constructor() { }

  uploadFile( file: any, fileName: string, bucketName: string, allowedTypes: string[] ): any {

    return new Observable( ( observer ) => {

      const fileExt = file.name.split( '.' ).reverse()[ 0 ].toLowerCase();

      if ( !allowedTypes.some( x => x === fileExt ) ) {
        observer.next( false );
        observer.complete();
        return;
      }

      AWS.config.update({
        region: env.aws.region,
        credentials: {
          accessKeyId     : env.aws.key,
          secretAccessKey : env.aws.secret
        }
      });
  
      const bucket     = new S3();
  
      const params = {
        Bucket             : bucketName,
        Key                : `${ fileName }`,
        Body               : file,
        ContentType        : `${ file.type }`,
        ContentDisposition : `inline; filename=${ fileName }`,
        ACL                : 'public-read',
      };
  
      bucket.putObject(params, function ( err, data ) {
        if (err) {
            console.error('There was an error uploading your file: ', err);
            observer.next( false );
            observer.complete();
        } else {
            console.info('Successfully uploaded file.', data);
            observer.next( true );
            observer.complete();
        }
      } );
    });

  }

}
