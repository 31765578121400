import { It_Routes } from './it-routes';
import { En_Routes } from './en-routes';

// export default function () {

//      let RoutesArray = [];

//      return RoutesArray
//                .concat( It_Routes )
//                .concat( En_Routes );

// }

export const CompositeRoutes = [
     ...It_Routes,
     ...En_Routes,
]

