import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarTemplateComponent } from '@shared/snack-bar-template/snack-bar-template.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

/**
 * Utility form for passord recovery Api
 */

@Component({
  selector: 'app-password-recovery-via-email',
  templateUrl: './password-recovery-via-email.component.html',
})
export class PasswordRecoveryViaEmailComponent implements OnInit {

  @Output() closeFormEvent : EventEmitter<boolean> = new EventEmitter();
  emailForm      : FormGroup;

  constructor( private fb        : FormBuilder,
               private translate : TranslateService,
               private snack     : MatSnackBar,
               private router    : Router,
              ) { }

  private buildForm(  ): void {

    this.emailForm = this.fb.group({
      email : [ '', [ Validators.required, Validators.email ] ],
    });
  }

  // utils
  get f() {
    return this.emailForm.controls;
  }

  keys(obj: any): any[] {
    if (!obj) {
      return [];
    }
    return Object.keys(obj);
  }

  public async onSubmitEmail() {

    Object.keys( this.emailForm.controls ).forEach( field => {
      const control = this.emailForm.get( field );
            control.markAsTouched({ onlySelf: true });
    });

    if ( !this.emailForm.valid ) { return; }

  }

  ngOnInit() {
    this.buildForm();

  }

}
