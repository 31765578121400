
<div class="row" *ngIf="!data.isForm || data.isForm === undefined || data.isForm === null">
  <div class="container text-center">
      <h1 *ngIf="data.title" mat-dialog-title class="title text__bold text__upper text-center" [innerHTML]="data.title"></h1>
  </div>
</div>
<div mat-dialog-content *ngIf="!data.isForm || data.isForm === undefined || data.isForm === null">
  <div class="row text-center">
      <div class="col-10 offset-1 text-center">
          <h1 *ngIf="data.icon" class="icon" >
              <i class="material-icons">{{ data.icon }}</i>
          </h1>
          <h4 *ngIf="data.subtitle" class="subtitle" [innerHTML]="data.subtitle"></h4>
          <p *ngIf="data.text" class="text" [innerHTML]="data.text"></p>
      </div>
  </div>
  <div class="row cta-wrapper">
    <div class="container text-center">
        <button *ngIf="data.cta" mat-raised-button color="accent">
          {{ data.cta }}
        </button>
    </div>
  </div>
</div>

<div *ngIf="data.isForm === true">
    <div class="container text-center">
      <div class="divider divider__40"></div>
        <h6 *ngIf="data.subtitle" class="text__brown" [innerHTML]="data.subtitle"></h6>
    </div>
    <div mat-dialog-content>
        <app-password-recovery-via-email *ngIf="data.isPswRecoveryForm" (closeFormEvent)="onNoClick()"></app-password-recovery-via-email>
    </div>
</div>