import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppRoutingModule } from '../app-routing.module';
import { AngularMaterialsModule } from '@shared/angular-materials/angular-materials.module';

// i18n
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Components
import { ComponentsModule } from '../components/components.module';

import { environment as env } from '@env/environment';


import { HomePageComponent } from './home-page/home-page.component';
import { GetSitemapPageComponent } from './get-sitemap-page/get-sitemap-page.component';
import { SharedModule } from '../shared/shared.module';

// pages


@NgModule({
  declarations: [

    // Components  ##
    HomePageComponent,
    GetSitemapPageComponent,
    

    // Components  ##

  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    AngularMaterialsModule,
    AngularFontAwesomeModule,
    ComponentsModule,
    ReactiveFormsModule,
    SharedModule,

    // i18n
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
  ],
  exports: [

    // Components  ##
    HomePageComponent,
    GetSitemapPageComponent,

    // Components  ##

  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
  ]
})
export class PagesModule {
  constructor() {}
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${env.i18nPrefix}/assets/i18n/`,
    '.json'
  );
}
