
export const environment = {
  i18nPrefix: '',
  storageName: 'app_angular_base',
  variables: {
    allowedFiles : [ 'jpg', 'jpeg', 'png', 'pdf' ],
    isLocal             : false,
    isDevelopment       : true,
    isTesting           : false,
    isProduction        : false,
    showLoginPages      : true,
    dates: [ // formatted as new Date( 'year/month/day' ).setHours( hours, minutes )
      {
        from: new Date( '2019/5/13' ).setHours( 11 ),
        to: new Date( '2019/7/26' ).setHours( 17 ),
      },
      {
        from: new Date( '2019/9/16' ).setHours( 11 ),
        to: new Date( '2019/11/22' ).setHours( 17 )
      }
    ],
  },
  // SERVICES #################################################################################################################################
  api: {
    endpoint: 'http://dev-edufarma.adviceplatform.it/api'
  },
  aws: {
    endpoint            : 'https://s3.eu-west-1.amazonaws.com',
    getaway             : 'execute-api.eu-west-1.amazonaws.com',
    secret              : 'mWrrLQDCrliAnwGH6nksZjX4dLhjKm3fslOqwFOc',
    key                 : 'AKIAJQC7REOH5M2CURZA',
    region              : 'eu-west-1',
    profileImagesBucket : 'mondadori.profilepictures',
    profileImagesPrefix : 'mondadori_profile',
    bucketsPath         : 'https://s3-eu-west-1.amazonaws.com',
    clientsFilesBucket  : 'mondadori.clients.files',
    clientsFilesPrefix  : 'mondadori_client_file',
    cognito             : {
      UserPoolId     : 'eu-west-1_RoxCSlmIX', // Your user pool id here
      ClientId       : '5luqcjb2steu4obetv94bvukia', // Your client id here
      // ClientId       : '6hh1408s830mtsr1vgnvl3qdif', // Your client id here
      IdentityPoolId : 'eu-west-1:076a033f-20fe-4a62-8112-825c9a67d7fb', // your identity pool id here
      PoolLocation   : 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_RoxCSlmIX',
    },
  },
  googleAnalytics: {
    domain: 'dataLayer',
    trackingId: 'UA-150555176-1'
  },
  facebook: {
    appId: '1155567677939677',
  }
};

