<div class="col-12 ">

	<mat-card class="background__primary">
		<mat-card-content>
			<form *ngIf="!( contactForm === null || contactForm === undefined )" [formGroup]="contactForm" class="row pb-5 pt-4 white_theme">

				<div class="col-12 offset-md-1 col-md-10">

					<div class="row">

						<div class="col-12"> 
							<h2 class="text__white text-center text-md-left" [innerHTML]="'app.components.contactForm.title' | translate"></h2>
						</div>

						<!-- name -->
						<div class="col-12 col-md-6 mb-3">
							<mat-form-field class="w-100 " appereance="standard">
								<mat-label>{{ 'app.common.forms.name' | translate }}</mat-label>
								<input matInput type="text" formControlName="name" required />
								<mat-hint class="text__white">{{ 'app.common.forms.name.hint' | translate }}</mat-hint>
								<mat-error *ngFor="let err of keys(f['name'].errors)">
									{{ 'app.errors.name.' + err | translate }}
								</mat-error>
							</mat-form-field>
						</div>

						<!-- surname -->
						<div class="col-12 col-md-6 mb-3">
							<mat-form-field class="w-100 " appereance="standard">
								<mat-label>{{ 'app.common.forms.surname' | translate }}</mat-label>
								<input matInput type="text" formControlName="surname" required />
								<mat-hint class="text__white">{{ 'app.common.forms.surname.hint' | translate }}</mat-hint>
								<mat-error *ngFor="let err of keys(f['surname'].errors)">
									{{ 'app.errors.surname.' + err | translate }}
								</mat-error>
							</mat-form-field>
						</div>

						<!-- city -->
						<div class="col-12 col-md-6 mb-3">
							<mat-form-field class="w-100 " appereance="standard">
								<mat-label>{{ 'app.common.forms.city' | translate }}</mat-label>
								<input matInput type="text" formControlName="city" required />
								<mat-hint class="text__white">{{ 'app.common.forms.city.hint' | translate }}</mat-hint>
								<mat-error *ngFor="let err of keys(f['city'].errors)">
									{{ 'app.errors.city.' + err | translate }}
								</mat-error>
							</mat-form-field>
						</div>

						<!-- email -->
						<div class="col-12 col-md-6 mb-3">
							<mat-form-field class="w-100 " appereance="standard">
								<mat-label>{{ 'app.common.forms.email' | translate }}</mat-label>
								<input matInput type="email" formControlName="email" required />
								<mat-hint class="text__white">{{ 'app.common.forms.email.hint' | translate }}</mat-hint>
								<mat-error *ngFor="let err of keys(f['email'].errors)">
									{{ 'app.errors.email.' + err | translate }}
								</mat-error>
							</mat-form-field>
						</div>

						<!-- message -->
						<div class="col-12 mb-3">
							<mat-form-field class="w-100 " appereance="standard">
								<mat-label>{{ 'app.common.forms.message' | translate }}</mat-label>
								<textarea matInput formControlName="message" required></textarea>
								<mat-hint class="text__white">{{ 'app.common.forms.message.hint' | translate }}</mat-hint>
								<mat-error *ngFor="let err of keys(f['message'].errors)">
									{{ 'app.errors.message.' + err | translate }}
								</mat-error>
							</mat-form-field>
						</div>

						<div class="col-12 mt-3 text-center text-md-left">

							<button mat-raised-button role="submit" (click)="onSubmit()" color="white">
								{{ 'app.common.buttons.send_message' | translate }}
							</button>

						</div>

					</div>

				</div>



			</form>

		</mat-card-content>
	</mat-card>

</div>