import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-what-is',
  templateUrl: './what-is.component.html',
})
export class WhatIsComponent implements OnInit {

  items: any[] = [];

  constructor(
    private translate: TranslateService,
  ) {
    this.translate.get( 'app.components.what.items' ).subscribe( x => this.items = x );
  }

  ngOnInit() {
  }

}
