import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as env } from '@env/environment';
import { LocalStorageHelperService } from '../_helpers/local-storage-helper.service';

@Injectable()
export class HttpHeadersInterceptorService implements HttpInterceptor {
  constructor(
    private storage: LocalStorageHelperService
    ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header

    if ( request.url.includes( env.api.endpoint ) ) {
      const userData = this.storage.getData( 'user_data' );

      request = request.clone({
        setHeaders: {
          Accept: `application/json`,
          'Content-Type': 'application/json'
        }
      });

      if ( userData !== null ) {
        if ( userData.hasOwnProperty( 'token' ) ) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${ userData[ 'token' ] }`,
            }
          });
        }

      }


    }

    return next.handle(request);
  }

}
