<div class="col-11 offset-0_5-md col-lg-12 offset-lg-0">

    <div class="row d-flex align-items-center justify-content-center flex-column flex-md-row">

        
        <div class="col-12 col-md-5 text-center text-md-left">
            <h2 [innerHTML]="'app.components.map.title' | translate"></h2>
            <p [innerHTML]="'app.components.map.text' | translate"></p>
            <a class="m-auto float-md-left d-flex align-items-center justify-content-center flex-row underlined__false with_icon"
                href="{{ 'app.components.map.CTA_url' | translate }}"
                target="_blank"
            >
                {{ 'app.common.buttons.discover_more' | translate }}
                <span class="material-icons text__color "> chevron_right </span>
            </a>
        </div>

        <div class="d-flex align-items-center justify-content-center flex-column col-12 col-md-6 offset-md-1">
            <div id="map_wrapper" class="d-flex align-items-center justify-content-center flex-column map__wrapper">
                <img data-depth="1" class="map__item map__item-background" src="/assets/images/map/{{ 'app.components.map.image.background' | translate }}" alt="{{ 'app.components.map.image.alt' | translate }}">
                <img data-depth="0.8" class="background__drop map__item map__item-map" src="/assets/images/map/{{ 'app.components.map.image.map' | translate }}" alt="{{ 'app.components.map.image.alt' | translate }}">
                <img data-depth="0.6" class="background__drop map__item map__item-info" src="/assets/images/map/{{ 'app.components.map.image.info' | translate }}" alt="{{ 'app.components.map.image.alt' | translate }}">
                <img data-depth="0.4" class="background__drop map__item map__item-search" src="/assets/images/map/{{ 'app.components.map.image.search' | translate }}" alt="{{ 'app.components.map.image.alt' | translate }}">
            </div>
        </div>

    </div>

</div>
