



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CompositeRoutes } from './routes/app-routing-loader';


import {
  MasterGuard,
  OnlyDev,
} from '@guards';

const appRoutes: Routes = [
  ...CompositeRoutes,
  {
    path: '**',
    redirectTo: '/'
  }
];


@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot( appRoutes, {
      useHash: true,
      // scrollPositionRestoration: 'enabled',
      scrollPositionRestoration: 'disabled',
      enableTracing: true,
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

