import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';


import { environment as env } from '@env/environment';
import { LocalStorageHelperService } from '../_helpers';


@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {

  constructor(
    private http    : HttpClient,
    private storage : LocalStorageHelperService,
  ) {
  }

  private useObservable( mockedObject: any ): any {
    return new Observable( (observer ) => {
      observer.next( mockedObject );
      observer.complete()
    });
  }

  public sendContactData( data: any ): any {
    return this.http.post( `https://ag8a14yyu4.execute-api.eu-central-1.amazonaws.com/dev-peco-promo`, data ).pipe( share() );
  }

}
