import { Component, OnInit } from '@angular/core';
import * as Parallax from 'parallax-js';

// declare var Parallax: any;

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
})
export class ExploreComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    
  }

  ngAfterContentInit() {
    
  }

  ngAfterViewInit() {
    if ( window.innerWidth > 500 ) {
      const scene = document.getElementById( 'map_wrapper' );
      const parallaxInstance = new Parallax( scene, {
        relativeInput: false,
      } );
    }
  }

}
